<template>
    <modal ref="modalEliminar" titulo="Eliminar Producto" icon="shopping-missing" @guardar="eliminar_producto">
        <cargando v-if="loading" />
        <ValidationObserver ref="form">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center f-14">
                    Seleccione el motivo de eliminación
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10 f-14">
                    <label class="f-14">Motivo de eliminación</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="motivo">
                        <el-select v-model="model.id_motivo" size="small" class="w-100">
                            <el-option
                            v-for="item in motivos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import PedidosTienda from '~/services/pedidos/pedidos-tienda'
import PedidosAdmin from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            model:{
                id_motivo:null,


            },
            motivos:[],
            id_eliminar:null,
            loading:false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido'
        })
    },
    methods:{
        toggle(id_pedido_producto){
            this.id_eliminar = id_pedido_producto
            this.select_motivos()
            this.model = {
                id_motivo:null,
            }
            this.$refs.modalEliminar.toggle()
        },
        async select_motivos(){
            try {
                let params = {
                    tipo:11
                }
                const {data} = await PedidosTienda.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminar_producto(){
            try {
                this.loading = true
                const valid = await this.$refs.form.validate();

                if(!valid) return
                const {data} = await PedidosAdmin.eliminar_producto(this.id_eliminar,this.model)

                this.notificacion('Mensaje','Producto eliminado','success')
                this.$emit('update')
                this.$refs.modalEliminar.toggle()


            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async rechazar_pedido(){
            try {

                const valid = await this.$refs.form.validate()

                if(valid){

                    if(this.id_pedido === null){
                        this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                        return
                    }

                    this.model.id_pedido = this.id_pedido
                    this.model.estado = 25
                    const {data} = await PedidosAdmin.rechazar_cancelar_tendero(this.model)

                    let model2 = {
                        id_pedido: this.pedido.id_pedido,
                        id_motivo: this.model.id_motivo,
                        justificacion: this.model.justificacion
                    }

                    await PedidosTienda.rechazar_tendero(model2)

                    this.$emit('accion-rechazar')
                    this.notificacion('Mensaje','Pedido rechazado','success')
                    this.$refs.modalEliminar.toggle()
                }


            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>

<style lang="css" scoped>
</style>
